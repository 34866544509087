export default {
  fund: [
    {
      title: "Главная Фонда «МКК Развитие»",
      subtitle:
        "Микрозаймы субъектам малого и среднего предпринимательства Архангельской области",
      link: "/",
    },
    {
      title: "Новости",
      subtitle:
        "Новости фонда, микрофинансовой отрасли, Фонда Развития Промышленности",
      link: "/news",
    },
    {
      title: "Услуги Фонда",
      subtitle: "Цели и задачи Фонда",
      link: "/fund/services",
    },
    {
      title: "Программы микрокредитования",
      subtitle:
        "Категории займов по программам микрокредитования предпринимателей",
      link: "/programs",
    },
    {
      title: "Подобрать программу",
      subtitle: "Калькулятор расчёта платежей по программам",
      link: "/calculator",
    },
    {
      title: "Подать заявку",
      subtitle: "Документы для подачи заявки",
      link: "/apply",
    },
    {
      title: "Отчетность по действующим займам",
      subtitle: "Формы отчетов для действующих заёмщиков",
      link: "/clients/reports",
    },
    {
      title: "Залог",
      subtitle: "Требования к залоговому имуществу, оценка, страхование",
      link: "/collateral",
    },
    {
      title: "О фонде",
      subtitle:
        "Контактная информация, учредитель, наблюдательный и попечительский советы, руководство",
      link: "/fund",
    },
    {
      title: "Региональный Фонд Развития Промышленности",
      subtitle:
        "Финансовая поддержка промышленным предприятиям Архангельской области",
      link: "/frp",
    },
  ],
  frp: [
    {
      title: "Главная регионального ФРП",
      subtitle:
        "Финансовая поддержка промышленным предприятиям Архангельской области",
      link: "/frp",
    },
    {
      title: "Программы РФРП",
      subtitle: "Программы регионального Фонда Развития Промышленности",
      link: "/frp/programs",
    },
    {
      title: "Подать заявку на заём",
      subtitle: "Документы для подачи заявки на заём",
      link: "/frp/apply",
    },
    {
      title: "Гранты РФРП",
      subtitle:
        "Компенсация процентов по кредитным договорам для промышленных предприятий",
      link: "/frp/grants",
    },
    {
      title: "Подать заявку на грант",
      subtitle: "Документы для подачи заявки на грант",
      link: "/frp/apply-for-grant",
    },
    {
      title: "О региональном ФРП",
      subtitle:
        "Контактная информация, экспертный совет, полномочия и соглашения",
      link: "/frp/about",
    },
    {
      title: "Фонд «МКК Развитие»",
      subtitle:
        "Микрозаймы субъектам малого и среднего предпринимательства Архангельской области",
      link: "/",
    },
  ],
};
