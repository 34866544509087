import React, { useState } from "react";
import PropTypes from "prop-types";

import { useStyletron } from "baseui";
import { Grid, Cell } from "baseui/layout-grid";

import { Button, SIZE, KIND, SHAPE } from "baseui/button";
import { Drawer, SIZE as DRAWER_SIZE, ANCHOR } from "baseui/drawer";
import Menu from "baseui/icon/menu";
import Delete from "baseui/icon/delete";

import Logo from "../components/logo";
import LogoFrp from "../components/logo-frp";
import MainMenuContent from "./main-menu-content";

import { useStateWithLocalStorage } from "../hooks/useStateWithLocalStorage";

import { Popover, ACCESSIBILITY_TYPE, PLACEMENT } from "baseui/popover";
import { Label3 } from "baseui/typography";

const Header = ({ data: { shortCompanyName }, theme }) => {
  const [css] = useStyletron();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isHintSeen, setIsHintSeen] =
    useStateWithLocalStorage("cmf-is-hint-seen");
  const drawerOverrides = {
    DrawerBody: {
      style: {
        paddingTop: "10px",
      },
    },
    Close: {
      style: {
        // hidden and visible on focus
        display: "block",
        left: "-999px",
        position: "absolute",
        width: "1px",
        height: "1px",
        overflow: "hidden",
        zIndex: -999,
        ":focus": {
          left: "initial",
          right: 5,
          top: 5,
          width: "25px",
          height: "25px",
          overflow: "auto",
          zIndex: 999,
        },
      },
    },
  };

  return (
    <header
      className={css({
        paddingTop: "10px",
      })}
    >
      <Grid>
        <Cell span={[3, 6, 11]}>
          {theme === "frp" ? (
            <LogoFrp shortCompanyName={shortCompanyName} />
          ) : (
            <Logo shortCompanyName={shortCompanyName} />
          )}
        </Cell>
        <Cell span={[1, 2, 1]}>
          <div
            className={css({
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
              height: "100%",
            })}
          >
            <Popover
              isOpen={!isHintSeen}
              accessibilityType={ACCESSIBILITY_TYPE.tooltip}
              showArrow
              placement={PLACEMENT.left}
              content={() => (
                <div
                  className={css({
                    padding: "20px",
                    textAlign: "right",
                  })}
                >
                  <Label3 marginTop={0} marginBottom={"10px"}>
                    Привет, это главное меню!
                  </Label3>
                  <Button
                    size={SIZE.mini}
                    kind={KIND.tertiary}
                    onClick={() => setIsHintSeen((value) => !value)}
                  >
                    Хорошо 👍
                  </Button>
                </div>
              )}
            >
              <div>
                <Button
                  onClick={() => setIsMenuOpen((value) => !value)}
                  size={SIZE.compact}
                  kind={KIND.secondary}
                  title={"Открыть главное меню"}
                  endEnhancer={<Menu title="Открыть главное меню" size={30} />}
                >
                  МЕНЮ
                </Button>
              </div>
            </Popover>
          </div>
        </Cell>
      </Grid>
      <Drawer
        isOpen={isMenuOpen}
        autoFocus={false}
        renderAll
        size={DRAWER_SIZE.auto}
        anchor={ANCHOR.right}
        onClose={() => setIsMenuOpen(false)}
        overrides={drawerOverrides}
      >
        <Button
          tabIndex={-1}
          onClick={() => setIsMenuOpen((value) => !value)}
          size={SIZE.compact}
          kind={KIND.secondary}
          shape={SHAPE.square}
          title={"Закрыть главное меню"}
          overrides={{
            BaseButton: {
              style: {
                float: "right",
              },
            },
          }}
        >
          <Delete title="Закрыть главное меню" size={30} />
        </Button>
        <MainMenuContent currentTheme={theme} />
      </Drawer>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: "",
};

export default Header;
