import React from "react";
import { Link } from "gatsby";

import { useStyletron } from "baseui";
import { Label1 } from "baseui/typography";

import FrpLogo from "../images/frp-logo.svg";
import { SMALL } from "../themes/media-queries";

const Logo = () => {
  const [css] = useStyletron();

  const titleContainerStyles = css({
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "left",
    padding: "20px 0",
  });

  const logoStyles = css({
    width: "180px",
    height: "100px",
    [SMALL]: {
      width: "99px",
      height: "55px",
    },
    padding: "0 20px",
  });

  const titleStyles = css({
    textTransform: "uppercase",
    [SMALL]: {
      fontSize: "15px",
      lineHeight: "15px",
      paddingBottom: "5px",
    },
  });

  return (
    <div className={titleContainerStyles}>
      <Link
        className={css({
          padding: 0,
          textDecoration: "none",
          color: "inherit",
          ":hover": { color: "inherit" },
          ":visited": { color: "inherit" },
        })}
        to={"/frp"}
      >
        <FrpLogo title="Фонд Развития Промышленности" className={logoStyles} />
      </Link>
      <Label1 className={titleStyles}>Архангельская область</Label1>
    </div>
  );
};

export default Logo;
