import React from "react";

import { Link } from "gatsby";

import { useStyletron } from "baseui";
import { Grid, Cell } from "baseui/layout-grid";
import { StyledLink } from "baseui/link";
import { Label2, Paragraph3 } from "baseui/typography";
import { Block } from "baseui/block";
import menuItems from "../data/main-menu";

const MainMenuContent = ({ currentTheme }) => {
  const [css, theme] = useStyletron();

  const menuContent = currentTheme === "frp" ? menuItems.frp : menuItems.fund;

  const menuLinks = menuContent.map((item) => {
    return (
      <StyledLink
        key={item.link.toString()}
        $as={Link}
        to={item.link}
        className={css({
          textDecoration: "none !important",
        })}
      >
        <Block
          className={css({
            padding: "15px",
            borderBottom: `1px solid ${theme.colors.mono400}`,
            ":hover": {
              backgroundColor: theme.colors.mono200,
            },
          })}
        >
          <Label2
            className={css({
              color:
                item.link === "/"
                  ? "#0E799E !important"
                  : item.link === "/frp"
                  ? "#D1451F !important"
                  : "rgb(84,84,84)",
            })}
          >
            {item.title}
          </Label2>
          <Paragraph3
            className={css({
              margin: "0px",
            })}
          >
            {item.subtitle}
          </Paragraph3>
        </Block>
      </StyledLink>
    );
  });

  return (
    <Grid>
      <Cell span={[4, 7, 11]}>{menuLinks}</Cell>
    </Grid>
  );
};

export default MainMenuContent;
