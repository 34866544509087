import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import { BaseProvider, useStyletron } from "baseui";
import { UserDataProvider } from "../context/userdata-provider";
import cmf from "../themes/cmf";
import frp from "../themes/frp";

import "./layout.css";
import Header from "./header";
import Footer from "./footer";
import SkipNavigation from "./skip-navigation";

const Layout = ({ theme, children }) => {
  const [css] = useStyletron();
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          company {
            shortCompanyName
            longCompanyName
            address
            phoneCode
            phoneNumber
            inn
            ogrn
          }
        }
      }
    }
  `);

  return (
    <BaseProvider theme={theme === "frp" ? frp : cmf}>
      <SkipNavigation />
      <UserDataProvider>
        <div
          className={css({
            /* next lines for sticky footer */
            display: `flex`,
            minHeight: `100vh`,
            flexDirection: `column`,
          })}
        >
          <Header theme={theme} data={data.site.siteMetadata.company} />
          <main
            id="main"
            tabIndex="-1"
            className={css({
              outline: 0,
              padding: `20px 0`,
            })}
          >
            {children}
          </main>
          <Footer data={data.site.siteMetadata.company} />
        </div>
      </UserDataProvider>
    </BaseProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
