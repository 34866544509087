import React, { useEffect } from "react";

export const useStateWithLocalStorage = (localStorageKey) => {
  const [value, setValue] = React.useState(
    typeof window !== "undefined"
      ? localStorage.getItem(localStorageKey) || ""
      : null
  );

  useEffect(() => {
    typeof window !== "undefined"
      ? localStorage.setItem(localStorageKey, value)
      : null;
  }, [value]);

  return [value, setValue];
};
