import { createTheme, lightThemePrimitives } from "baseui";

const primitives = {
  ...lightThemePrimitives,
  primary: "#0E799E",
  primaryA: "#545454",
  primaryB: "#FFFFFF",
  primary50: "#f5fbfc", // hover kind="minimal", "ternary"
  primary100: "#e9f5f7", // secondary button color
  primary200: "#dfeff2", //hover secondary button
  primary300: "#cceaf0", // :active secondary button
  primary400: "#0E799E",
  primary500: "#2886AB",
  primary600: "#0d6e94",
  primary700: "#085e80",
  accent: "#ff6a45",
  accent50: "#FFFCF7",
  accent100: "#FFF9EF",
  accent200: "#FFB68F",
  accent300: "#FF9973",
  accent400: "#ff6a45",
  accent500: "#DB4732",
  accent600: "#B72A22",
  accent700: "#931619",
};

const overrides = {
  colors: {
    linkText: primitives.primary,
    linkVisited: primitives.primary500,
    linkHover: primitives.primary500,
    linkActive: primitives.primary600,
    buttonPrimaryHover: primitives.primary500,
    buttonPrimaryActive: primitives.primary
  },
};

const theme = createTheme(primitives, overrides);

export default theme;
