import { createTheme, lightThemePrimitives } from "baseui";

const primitives = {
  ...lightThemePrimitives,
  primary: "#D14938",
  primaryA: "#545454",
  primaryB: "#FFFFFF",
  primary50: "#FEF8ED", // hover kind="minimal", "ternary"
  primary100: "#FEF3E4", // secondary button color
  primary200: "#FCE7D1", //hover secondary button
  primary300: "#E37B52", // :active secondary button
  primary400: "#D1451F",
  primary500: "#B32C16",
  primary600: "#96180F",
  primary700: "#79090A",
  accent: "#197BFC",
  accent50: "#D0ECFE",
  accent100: "#A2D6FE",
  accent200: "#74BCFE",
  accent300: "#52A3FD",
  accent400: "#197BFC",
  accent500: "#125FD8",
  accent600: "#0C46B5",
  accent700: "#073192",
};

const overrides = {
  colors: {
    linkText: primitives.primary,
    linkVisited: primitives.primary500,
    linkHover: primitives.primary500,
    linkActive: primitives.primary600,
    buttonPrimaryHover: primitives.primary500,
    buttonPrimaryActive: primitives.primary
  },
};

const theme = createTheme(primitives, overrides);

export default theme;
