import React from "react";

import { useStyletron } from "baseui";
import { Grid, Cell } from "baseui/layout-grid";
import { Paragraph2, Label2, Paragraph1 } from "baseui/typography";
import { StyledLink } from "baseui/link";

import CherniaevLogo from "../images/cherniaev.svg";
import { SMALL } from "../themes/media-queries";

const Footer = ({
  data: {
    shortCompanyName,
    longCompanyName,
    address,
    phoneNumber,
    phoneCode,
    inn,
    ogrn,
  },
}) => {
  const [css, theme] = useStyletron();
  const alignRight = css({
    textAlign: `right`,
  });
  const alignCenterSmall = css({
    [SMALL]: {
      textAlign: `center`,
    },
  });
  return (
    <footer
      className={css({
        background: theme.colors.primary50,
        padding: `40px 0`,
        /* next line for sticky footer */
        marginTop: `auto`,
      })}
    >
      <Grid>
        <Cell span={12}>
          <Label2>{longCompanyName} ({shortCompanyName})</Label2>
          <Paragraph2>{address}</Paragraph2>
          <Paragraph2>
            ИНН {inn}, ОГРН {ogrn}
          </Paragraph2>
          <Label2>Приём клиентов</Label2>
          <Paragraph2>
            Понедельник – Четверг: с 10:00 – 17:00
            <br />
            Пятница: с 10:00 – 13:00
            <br />
            Суббота, воскресенье: выходной
          </Paragraph2>
          <Paragraph2>
            <StyledLink
              href={`tel:+7${phoneCode}${phoneNumber.split("-").join("")}`}
            >
              ({phoneCode}) {phoneNumber}
            </StyledLink>
          </Paragraph2>
          <Paragraph2>
            <em>
              Внесена в{" "}
              <StyledLink
                title="Реестры субъектов рынка микрофинансирования"
                target="noopener"
                href="https://www.cbr.ru/microfinance/registry/"
              >
                государственный реестр микрофинансовых организаций
              </StyledLink>{" "}
              29 июля 2011 года, регистрационный номер 6110229000264,
              свидетельство Банка России серия 01 № 003770
            </em>
          </Paragraph2>
          <Paragraph2>
            <StyledLink
              title="Банк России"
              target="noopener"
              href="https://www.cbr.ru/"
            >
              Банк России
            </StyledLink>{" "}
            &#183;{" "}
            <StyledLink
              title="Интернет-приемная Банка России"
              target="noopener"
              href="https://www.cbr.ru/Reception/"
            >
              Интернет-приемная Банка России
            </StyledLink>{" "}
            &#183;{" "}
            <StyledLink
              title="Рэнкинг микрофинансовых организаций"
              target="noopener"
              href="https://www.ra-national.ru/ru/taxonomy/term/3296?type=rating"
            >
              Рейтинг МФО
            </StyledLink>
          </Paragraph2>
          <Paragraph2>
            Фонд является членом саморегулируемой организации{" "}
            <StyledLink
              href="https://alliance-mfo.ru/"
              target="_blank"
              rel="noopener noreferrer"
            >
              МФО Союз "Микрофинансовый альянс "Институты развития малого и
              среднего бизнеса"
            </StyledLink>{" "}
            c 21.07.2014.
            <br />
            Адрес: 127055, г. Москва, ул. Сущёвская, д. 21, офис 513, тел.
            8(800) 3336867, тел./факс (495) 7772203, e-mail:
            info@alliance-mfo.ru
          </Paragraph2>
          <Paragraph2>
            Для досудебного урегулирования споров между потребителями финансовых
            услуг и финансовыми организациями вы можете обратиться к финансовому
            уполномоченному на горячую линию{" "}
            <StyledLink href={`tel:88002000010`}>8 800 200-00-10</StyledLink>{" "}
            или направить письменное обращение по адресу 119017, г. Москва,
            Старомонетный пер., дом 3.
            <br />
            Подробная информация на сайте{" "}
            <StyledLink
              href="https://finombudsman.ru/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://finombudsman.ru/
            </StyledLink>
          </Paragraph2>
        </Cell>
      </Grid>
      <Grid>
        <Cell span={[4, 4, 6]}>
          <Paragraph2 className={alignCenterSmall}>
            © {new Date().getFullYear()} {shortCompanyName}
          </Paragraph2>
        </Cell>
        <Cell span={[4, 4, 6]}>
          <Paragraph2 className={`${alignRight} ${alignCenterSmall}`}>
            <StyledLink
              title="Разработка сайта"
              target="noopener"
              href="https://cherniaev.com"
            >
              <CherniaevLogo />
            </StyledLink>
          </Paragraph2>
        </Cell>
      </Grid>
    </footer>
  );
};

export default Footer;
