import React from "react";
import PropTypes from "prop-types";

import { Link } from "gatsby";

import { useStyletron } from "baseui";

import CmfLogo from "../images/cmf-logo.svg";
import MyBusiness from "../images/my-biz.svg";

import { LARGE, MEDIUM, SMALL } from "../themes/media-queries";
import theme from "../themes/cmf";

const Logo = ({ shortCompanyName }) => {
  const [css] = useStyletron();
  const cmfLogo = css({
    [LARGE]: {
      width: "77px",
      height: "82px",
      padding: "10px 20px 0",
    },
    [MEDIUM]: {
      width: "63px",
      height: "67px",
      padding: "0 15px 0 0",
    },
    [SMALL]: {
      width: "45px",
      height: "48px",
      padding: "0 20px 0 0",
    },
  });
  const bizLogo = css({
    [LARGE]: {
      width: "180px",
      height: "100px",
    },
    [MEDIUM]: {
      display: "none",
    },
    [SMALL]: {
      display: "none",
    },
    padding: "0 20px",
  });
  const titleContainer = css({
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "left",
    padding: "20px 0",
  });
  const title = css({
    paddingTop: "15px",
    textTransform: "uppercase",
    fontSize: "1.25rem",
    color: theme.colors.primary,
    letterSpacing: "-0.5px",
    lineHeight: 1.3,
    whiteSpace: "nowrap",
    [MEDIUM]: {
      fontSize: "1.1rem",
      padding: 0,
      letterSpacing: 0,
    },
    [SMALL]: {
      padding: 0,
    },
  });

  return (
    <div className={titleContainer}>
      <Link
        className={css({
          padding: 0,
          textDecoration: "none",
          color: "inherit",
          ":hover": { color: "inherit" },
          ":visited": { color: "inherit" },
        })}
        to={"/"}
      >
        <MyBusiness className={bizLogo} />
        <CmfLogo className={cmfLogo} />
      </Link>
      <span
        className={`${title} ${css({
          [SMALL]: {
            display: "none",
          },
        })}`}
      >
        Микрокредитная компания
        <br />
        Архангельский региональный
        <br />
        фонд «Развитие»
      </span>
      <span
        className={`${title} ${css({
          [LARGE]: {
            display: "none",
          },
          [MEDIUM]: {
            display: "none",
          },
        })}`}
      >
        {shortCompanyName}
      </span>
    </div>
  );
};

Logo.propTypes = {
  shortCompanyName: PropTypes.string.isRequired,
};

Logo.defaultProps = {
  shortCompanyName: "Фонд «MKK Развитие»",
};

export default Logo;
