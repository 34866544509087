import React, { useState, createContext } from "react";

export const UserDataContext = createContext();

export const UserDataProvider = ({ children }) => {
  const [user, setUser] = useState(
    typeof window !== "undefined"
      ? localStorage.getItem("sme")
        ? [JSON.parse(localStorage.getItem("sme"))]
        : []
      : null
  );

  const changeUser = (user) => {
    if (!user[0]) {
      typeof window !== "undefined" ? localStorage.removeItem("sme") : null;
      setUser([]);
    } else {
      setUser([user[0]]);
      typeof window !== "undefined"
        ? localStorage.setItem("sme", JSON.stringify(user[0]))
        : null;
    }
  };

  return (
    <UserDataContext.Provider value={{ user: user, setUser: changeUser }}>
      {children}
    </UserDataContext.Provider>
  );
};
