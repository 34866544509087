import React from "react";

import { useStyletron } from "baseui";

const SkipToMainContentLink = () => {
  const [css, theme] = useStyletron();

  const skipMain = css({
    display: "block",
    left: "-999px",
    position: "absolute",
    top: "auto",
    width: "1px",
    height: "1px",
    overflow: "hidden",
    zIndex: -999,
    ":focus": {
      color: theme.colors.primary,
      backgroundColor: theme.colors.white,
      left: 0,
      top: 50,
      width: "auto",
      height: "auto",
      overflow: "auto",
      margin: "3px",
      padding: "15px",
      outlineWidth: "3px",
      outlineStyle: "solid",
      outlineColor: theme.colors.accent,
      fontSize: "1.2em",
      zIndex: 999,
    },
  });

  return (
    <a className={skipMain} href="#main">
      Перейти к основному контенту
    </a>
  );
};

export default SkipToMainContentLink;
